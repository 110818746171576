import Container from "@material-ui/core/Container";
import { ChartData, ChartDataset } from "chart.js";
import * as React from "react"
import { Layout } from "../components/layout";
import { ScoreDevelopment } from "../components/score-development";
import { tables, teams } from "../libs/models";


const ProgressionPage: React.FC = () => {
  const labels = tables.map((table) => table.round);
  const datasets: {[key: string]: ChartDataset} = {};
  Object.values(teams).forEach((team) => datasets[team.id] = {
    label: team.name,
    data: [],
    backgroundColor: team.colour,
    borderColor: team.colour
  });
  tables.forEach((table) => table.table.forEach((id, i) => datasets[id].data.push(i+1)));
  const data: ChartData = {
    labels,
    datasets: Object.values(datasets)
  };

  return (
    <Layout>
      <Container>
        <h1>Udviklingen i ligaen</h1>
        <ScoreDevelopment chartData={data}/>
      </Container>
    </Layout>
  )
}

export default ProgressionPage;
